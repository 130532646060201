import Newsletter from '../../../Newsletter/Newsletter';
import React from 'react';

const CmsNewsletter = ({ listIds, title, subtitle, buttonLabel }) => (
  <aside aria-label="Newsletter">
    <Newsletter
      buttonLabel={buttonLabel}
      listIds={listIds}
      subtitle={subtitle}
      title={title}
    />
  </aside>
);

CmsNewsletter.size = 'xl';

export default CmsNewsletter;
