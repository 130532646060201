import styles from './CmsEventCalendar.module.scss';
import CmsEventCard from '../CmsEventCard/CmsEventCard';
import React from 'react';

const CmsEventCalendar = ({ items }) => (
  <div className={styles.eventCalendar}>
    {items?.map(event => (
      <CmsEventCard {...event} key={`${event.title}`} />
    ))}
  </div>
);

CmsEventCard.defaultProps = {
  items: [],
};

export default CmsEventCalendar;
