import styles from './HelpWidget.module.scss';
import { CmsCallToAction, usePageData } from '@wienenergiegithub/components';
import api from '@wienenergiegithub/components/src/common/api';
import React, { useEffect, useRef, useState } from 'react';
import {
  Dialog,
  DialogDisclosure,
  Form,
  FormSubmit,
  useDialogStore,
  useFormStore,
} from '@ariakit/react';
import {
  Button,
  Content,
  FontAwesomeProIcon,
  Heading,
  Stars,
  Textarea,
} from '@wienenergiegithub/ui-next';
import classNames from 'classnames';
import { useTranslation } from '@wienenergiegithub/modules-next/hooks';
import PropTypes from 'prop-types';

const widget = {
  OPEN: 'open',
  CLOSED: 'closed',
};

const view = {
  START: 'start',
  SURVEY: 'survey',
  SURVEY_ERR: 'survey-err',
  SURVEY_THX: 'survey-thx',
};

const HelpWidget = ({ visible }) => {
  const { helpWidget } = usePageData();

  const active = helpWidget?.active;
  const serviceButtons = helpWidget?.serviceButtons ?? [];
  const showCfm = helpWidget?.showCfm ?? false;

  const dialog = useDialogStore({
    visible,
    animated: true,
    modal: false,
  });

  const [values, setValues] = useState({
    ratingContent: null,
    ratingDesign: null,
    ratingUsability: null,
    message: '',
  });
  const [widgetState, setWidgetState] = useState(
    visible ? widget.OPEN : widget.CLOSED,
  );
  const [viewState, setViewState] = useState(view.START);
  const containerRef = useRef(null);
  const textareaRef = useRef(null);
  const { t } = useTranslation('helpwidget');

  const handleChange = () => {
    textareaRef.current.style.height = '53px';
    textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
  };

  const feedBackForm = useFormStore({
    values,
    setValues,
  });

  feedBackForm.useSubmit(async () => {
    try {
      const postValues = {
        ...values,
        browser: window.navigator.userAgent,
        href: window.location.href,
        width:
          window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth,
        height:
          window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight,
      };
      await api.post(`/cfm/v1/feedback`, postValues);
      setViewState(view.SURVEY_THX);
      feedBackForm.reset();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Error', error);
      setViewState(view.SURVEY_ERR);
    }
  });

  const handleToggle = () => {
    switch (widgetState) {
      case widget.OPEN:
        setWidgetState(widget.CLOSED);
        dialog.toggle();
        break;
      case widget.CLOSED:
        setViewState(view.START);
        setWidgetState(widget.OPEN);
        dialog.toggle();
        break;
      default:
        setWidgetState(widget.OPEN);
        dialog.toggle();
    }
  };

  useEffect(() => {
    if (dialog.visible && containerRef.current?.scrollTop) {
      containerRef.current.scrollTop = 0;
    }
  }, [dialog.visible, viewState]);

  const renderView = id => {
    switch (id) {
      case view.SURVEY:
        return (
          <Form store={feedBackForm}>
            <Heading size="h4">{t('surveyHeadline')}</Heading>
            <Content notResponsive size="regular">
              {t('surveyHeadline2')}
            </Content>
            <Heading as="h6" size="h7">
              {t('surveyContent')}
            </Heading>
            <Stars
              className={styles.stars}
              name="ratingContent"
              store={feedBackForm}
            />
            <Heading as="h6" size="h7">
              {t('surveyDesign')}
            </Heading>
            <Stars
              className={styles.stars}
              name="ratingDesign"
              store={feedBackForm}
            />
            <Heading as="h6" size="h7">
              {t('surveyUsability')}
            </Heading>
            <Stars
              className={styles.stars}
              name="ratingUsability"
              store={feedBackForm}
            />
            <Heading as="h6" size="h7">
              {t('surveyOptimize')}
            </Heading>
            <Textarea
              label={t('surveyMessageLabel')}
              name="message"
              onChange={handleChange}
              ref={textareaRef}
              store={feedBackForm}
            />
            <br />
            <FormSubmit className={styles.button} render={<Button />}>
              {t('giveFeedback')}
            </FormSubmit>
            <Button
              className={styles.button}
              onClick={() => {
                setViewState(view.START);
              }}
              variant="outline"
            >
              {t('surveyBack')}
            </Button>
          </Form>
        );
      case view.SURVEY_ERR:
        return (
          <>
            <Heading size="h4">{t('generalError')}</Heading>
            <Content notResponsive size="regular">
              {t('surveyError')}
            </Content>
            <Button
              className={styles.button}
              onClick={() => {
                setViewState(view.OPEN);
              }}
            >
              {t('surveyBack')}
            </Button>
          </>
        );
      case view.SURVEY_THX:
        return (
          <>
            <Heading size="h4">{t('surveySuccessHeadline')}</Heading>
            <Content notResponsive size="regular">
              {t('surveySuccess')}
            </Content>
            <Button
              className={styles.button}
              onClick={() => {
                dialog.toggle();
              }}
            >
              {t('surveyClose')}
            </Button>
          </>
        );
      default:
        return (
          <>
            <Heading className={styles.heading} noSpacing size="h4">
              {t('letUsTalk')}
            </Heading>
            <Content notResponsive size="regular">
              {t('energyIdeasForTomorrow')}
            </Content>
            <CmsCallToAction
              className={styles.callToAction}
              items={serviceButtons}
            />
            {showCfm && (
              <>
                <br />
                <Heading className={styles.heading} noSpacing size="h4">
                  {t('feedbackHeadline')}
                </Heading>
                <Content notResponsive size="regular">
                  {t('feedbackQuestion')}
                </Content>
                <Button
                  className={styles.button}
                  onClick={() => {
                    setViewState(view.SURVEY);
                  }}
                >
                  {t('giveFeedback')}
                </Button>
              </>
            )}
          </>
        );
    }
  };

  if (!active) {
    return null;
  }

  return (
    <>
      <Dialog
        className={styles.container}
        preventBodyScroll={false}
        ref={containerRef}
        store={dialog}
      >
        {renderView(viewState)}
      </Dialog>
      <DialogDisclosure
        className={styles.floatingButton}
        id="helpFloatingButton"
        store={dialog}
        toggle={handleToggle}
      >
        <FontAwesomeProIcon
          className={classNames(styles.closeIcon, {
            [styles.visible]: widgetState !== widget.CLOSED,
          })}
          icon="times"
          role="presentation"
        />
        <span
          className={classNames(styles.floatingButtonText, {
            [styles.visible]: widgetState === widget.CLOSED,
          })}
        >
          {t('buttonText')}
        </span>
      </DialogDisclosure>
    </>
  );
};

HelpWidget.propTypes = {
  visible: PropTypes.bool,
};

HelpWidget.defaultProps = {
  visible: false,
};

export default HelpWidget;
