import Page from '../../templates/default.js';
import { Wrapper, PageDataProvider } from '@wienenergiegithub/components';
import React from 'react';
import { setupTranslations } from '@wienenergiegithub/modules-next/hooks';

setupTranslations(window.pageData.translations);

export const App = () => (
  <PageDataProvider value={window.pageData}>
    <Wrapper>
      <Page />
    </Wrapper>
  </PageDataProvider>
);

export default App;
