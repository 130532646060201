/* for API Documentation please checkout https://github.com/nygardk/react-share */

function isMobileOrTablet() {
  return /(android|iphone|ipad|mobile)/i.test(navigator.userAgent);
}

function assert(value, message) {
  if (!value) {
    throw new Error(message);
  }
}

function objectToGetParams(object) {
  const params = Object.entries(object)
    .filter(([, value]) => value !== undefined && value !== null)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`,
    );

  return params.length > 0 ? `?${params.join('&')}` : '';
}

export function facebookLink(url, { quote, hashtag }) {
  assert(url, 'facebook.url');

  return `https://www.facebook.com/sharer/sharer.php${objectToGetParams({
    u: url,
    quote,
    hashtag,
  })}`;
}

export function twitterLink(url, { title, via, hashtags = [], related = [] }) {
  assert(url, 'twitter.url');
  assert(Array.isArray(hashtags), 'twitter.hashtags is not an array');
  assert(Array.isArray(related), 'twitter.related is not an array');

  return `https://twitter.com/share${objectToGetParams({
    url,
    text: title,
    via,
    hashtags: hashtags.length > 0 ? hashtags.join(',') : undefined,
    related: related.length > 0 ? related.join(',') : undefined,
  })}`;
}

export function whatsappLink(url, { title, separator = ' ' }) {
  assert(url, 'whatsapp.url');
  return `https://${
    isMobileOrTablet() ? 'api' : 'web'
  }.whatsapp.com/send${objectToGetParams({
    text: title ? title + separator + url : url,
  })}`;
}

export function linkedinLink(url, { title, summary, source }) {
  assert(url, 'linkedin.url');

  return `https://linkedin.com/shareArticle${objectToGetParams({
    url,
    mini: 'true',
    title,
    summary,
    source,
  })}`;
}

export function emailLink(url, { subject, body, separator }) {
  return `mailto:${objectToGetParams({
    subject,
    body: body ? body + separator + url : url,
  })}`;
}
