import {
  emailLink,
  facebookLink,
  linkedinLink,
  twitterLink,
  whatsappLink,
} from './SocialMenuSharingLinks';
import styles from './SocialMenu.module.scss';
import { FontAwesomeProIcon, Link } from '@wienenergiegithub/ui-next';
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const getSharingLink = (icon, sharingOptions) => {
  const blogUrl = window.location.href;

  switch (icon) {
    case 'facebook-square':
      return facebookLink(blogUrl, sharingOptions);
    case 'x-twitter':
      return twitterLink(blogUrl, sharingOptions);
    case 'whatsapp':
      return whatsappLink(blogUrl, sharingOptions);
    case 'linkedin':
      return linkedinLink(blogUrl, sharingOptions);
    case 'envelope':
      return emailLink(blogUrl, sharingOptions);
    default:
      break;
  }

  return null;
};

const SocialMenu = ({
  socialMenu,
  theme,
  justifyContent,
  className,
  dense,
}) => (
  <nav aria-label="Social Media" id="social-nav">
    <ul
      className={classNames(
        styles.socialMenu,
        styles[justifyContent],
        className,
      )}
    >
      {socialMenu &&
        socialMenu.map(
          ({ iconName, iconType, title, sharingOptions = {}, url }) => {
            const to = url || getSharingLink(iconName, sharingOptions);

            return (
              <li className={styles.socialMenuItem} key={title}>
                <Link
                  aria-label={title}
                  className={classNames(styles.socialMenuLink, {
                    [styles.dense]: dense,
                  })}
                  data-purpose={iconName}
                  rel="nofollow noopener"
                  target="_blank"
                  to={to}
                >
                  <FontAwesomeProIcon
                    className={classNames(styles.socialMenuIcon, styles[theme])}
                    fixedWidth
                    icon={iconName}
                    role="presentation"
                    type={iconType}
                  />
                </Link>
              </li>
            );
          },
        )}
    </ul>
  </nav>
);

SocialMenu.propTypes = {
  justifyContent: PropTypes.oneOf(['start', 'center', 'end']),
  socialMenu: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      // for API Documentation please checkout https://github.com/nygardk/react-share
      // eslint-disable-next-line react/forbid-prop-types
      sharingOptions: PropTypes.object,
      url: PropTypes.string,
    }),
  ).isRequired,
  theme: PropTypes.oneOf(['default', 'contrast']),
};

SocialMenu.defaultProps = {
  justifyContent: 'center',
  theme: 'default',
};

export { SocialMenu as default };
