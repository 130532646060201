import styles from './CmsEventCard.module.scss';
import wienEnergieIcon from '../../../../images/wien-energie-event.png';
import {
  dateFormatterGetDay,
  dateFormatterGetMonth,
  dateFormatterGetTime,
  dateFormatterGetTimePeriod,
  dateFormatterInclWeekday,
} from '../../helpers.js';
import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardAction,
  CardBody,
  Content,
  FontAwesomeProIcon,
  Heading,
  Link,
} from '@wienenergiegithub/ui-next';
import { VisuallyHidden } from '@ariakit/react';
import classNames from 'classnames';

export const CmsEventCard = ({
  title,
  limitHeading,
  description,
  limitText,
  startDate,
  endDate,
  location,
  link,
  wienenergieEvent,
}) => {
  const ellipsis = '…';
  const startDay = new Date(startDate).getDate();
  const endDay = new Date(endDate).getDate();

  return (
    <Card className={styles.card} variant="elevated">
      <CardBody className={styles.cardBody}>
        <div className={styles.dateBox}>
          <div className={classNames(styles.dateDayContainer, styles.day)}>
            {dateFormatterGetDay(startDate)}
          </div>
          <span className={styles.month}>
            {dateFormatterGetMonth(startDate)}
          </span>
        </div>

        {wienenergieEvent && (
          <div className={styles.wienEnergieBox}>
            <img alt="wien-energie" src={wienEnergieIcon} />
          </div>
        )}

        <Heading className={styles.title} size="h4">
          {limitHeading && title.length > limitHeading
            ? `${title.substring(0, limitHeading)}${ellipsis}`
            : title}
        </Heading>

        {limitHeading && (
          <VisuallyHidden>
            <Heading size="h4">{title}</Heading>
          </VisuallyHidden>
        )}

        <Content
          aria-hidden={!!limitText}
          className={styles.description}
          noSpacing
          notResponsive
          size="regular"
          withParser
        >
          {limitText && description?.length > limitText
            ? `${description.substring(0, limitText)}${ellipsis}`
            : description}
        </Content>

        {limitText && (
          <VisuallyHidden>
            <Content notResponsive>{description}</Content>
          </VisuallyHidden>
        )}

        <div className={styles.infoContainer}>
          <div className={styles.dateContainer}>
            <FontAwesomeProIcon
              className={styles.icon}
              fixedWidth
              icon="clock"
              role="none"
              type="solid"
            />
            <div>
              {startDay === endDay ? (
                <>
                  <span className={styles.infoTag}>
                    {dateFormatterInclWeekday(startDate)}
                  </span>
                  {endDate && (
                    <div>{dateFormatterGetTimePeriod(startDate, endDate)}</div>
                  )}
                </>
              ) : (
                <>
                  <div>
                    von{' '}
                    <span className={styles.infoTag}>
                      {dateFormatterInclWeekday(startDate)},
                    </span>{' '}
                    {dateFormatterGetTime(startDate)}
                  </div>
                  <div>
                    bis{' '}
                    <span className={styles.infoTag}>
                      {dateFormatterInclWeekday(endDate)},
                    </span>{' '}
                    {dateFormatterGetTime(endDate)}
                  </div>
                </>
              )}
            </div>
          </div>

          {location && (
            <div className={styles.locationContainer}>
              <FontAwesomeProIcon
                className={styles.icon}
                fixedWidth
                icon="map-marker-alt"
                role="none"
                type="solid"
              />
              <div className={styles.infoTag}>{location}</div>
            </div>
          )}
        </div>
      </CardBody>
      <CardAction>
        <Button
          aria-label="Besuchen"
          as={Link}
          rel={link.rel}
          shape="bubble"
          tabIndex={-1}
          target={link.target}
          to={link.url}
        >
          <FontAwesomeProIcon icon="chevron-right" role="none" />
        </Button>
      </CardAction>
    </Card>
  );
};

CmsEventCard.propTypes = {
  description: PropTypes.string.isRequired,
  endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  limitHeading: PropTypes.number,
  limitText: PropTypes.number,
  link: PropTypes.shape({
    target: PropTypes.string,
    rel: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
  location: PropTypes.string.isRequired,
  startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)])
    .isRequired,
  title: PropTypes.string.isRequired,
  wienenergieEvent: PropTypes.bool,
};

CmsEventCard.defaultProps = {
  endDate: null,
  limitHeading: 50,
  limitText: 160,
  wienenergieEvent: false,
};

export default CmsEventCard;
