import styles from './BlogCard.module.scss';
import wienenergieStudy from '../../images/logo-wienenergie-studie.png';
import {
  Card,
  CardBody,
  CardImage,
  Content,
  Heading,
  Image,
  Link,
} from '@wienenergiegithub/ui-next';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const BlogCard = ({
  title,
  category,
  image,
  excerpt,
  link,
  editor,
  date,
  readingTime,
  className,
  isFromWienenergie,
}) => (
  <Card
    as={Link}
    className={classNames(className, styles.card)}
    to={link}
    variant="elevated"
  >
    {image && (
      <CardImage aspectRatio={2 / 3} image={image} role="presentation" />
    )}
    {isFromWienenergie && (
      <div className={styles.wienenergieStudy}>
        <img alt="wienenergie_studie" src={wienenergieStudy} />
      </div>
    )}
    <CardBody className={styles.body}>
      <div>
        {category && <span className={styles.category}>{category}</span>}
        <Heading as="h3" className={styles.title} size="h4" withParser>
          {title}
        </Heading>
        <Content className={styles.content}>{excerpt}</Content>
      </div>
      <div className={styles.footer}>
        {editor && (
          <div className={styles.container}>
            {editor.avatar && (
              <div className={styles.avatar}>
                <Image alt={editor.name} src={editor.avatar.sizes.thumbnail} />
              </div>
            )}
            <span className={styles.editor}>{editor.name}</span>
          </div>
        )}
        <div className={styles.container}>
          <time className={styles.time}>{date}</time>
          {readingTime && (
            <>
              <div className={styles.dot} />
              <time className={styles.time}>
                {`${readingTime} Min. Lesezeit`}
              </time>
            </>
          )}
        </div>
      </div>
    </CardBody>
  </Card>
);

BlogCard.propTypes = {
  category: PropTypes.string,
  className: PropTypes.string,
  date: PropTypes.string.isRequired,
  editor: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    avatar: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
  }),
  excerpt: PropTypes.string.isRequired,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    srcSet: PropTypes.string,
  }).isRequired,
  isFromWienenergie: PropTypes.bool,
  link: PropTypes.string.isRequired,
  readingTime: PropTypes.string,
  title: PropTypes.string.isRequired,
};

BlogCard.defaultProps = {
  category: null,
  className: null,
  editor: null,
  isFromWienenergie: false,
  readingTime: null,
};

export default BlogCard;
