import styles from './CmsTwitter.module.scss';
import React from 'react';
import { Card, Content, FontAwesomeProIcon } from '@wienenergiegithub/ui-next';
import twitter from 'twitter-text';
import RelativeTime from '@yaireo/relative-time';

const getTweetTime = time => {
  const date1 = new Date(time);
  const date2 = new Date();

  // To calculate the time difference of two dates
  const diffInTime = date2.getTime() - date1.getTime();

  // To calculate the no. of days between two dates
  const diffInDays = diffInTime / (1000 * 3600 * 24);
  if (diffInDays < 3) {
    const relativeTime = new RelativeTime({ locale: 'de' });
    return relativeTime.from(new Date(time));
  }

  return new Date(time).toLocaleDateString('de-AT', {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
  });
};

const TwitterCard = ({ item }) => {
  const tweetDate = getTweetTime(item.created_at);
  return (
    <Card className={styles.tweet}>
      <div className={styles.avatar}>
        <img
          alt={`Twitter avatar von ${item.user.screen_name}`}
          className={styles.avatarImage}
          src={item.cached_avatar}
        />
        <div>
          <a
            className={styles.name}
            href={`https://twitter.com/${item.user.screen_name}`}
            rel="noreferrer"
            target="_blank"
          >
            {item.user.name}
          </a>
          <Content className={styles.handle} noSpacing>
            @{item.user.screen_name}
          </Content>
        </div>
        <a
          className={styles.tweetLink}
          href={`https://twitter.com/${item.user.screen_name}/status/${item.id_str}`}
          rel="noreferrer"
          target="_blank"
        >
          <FontAwesomeProIcon
            className={styles.tweetIcon}
            icon="twitter"
            role="presentation"
            type="brand"
          />
        </a>
      </div>
      <Content className={styles.content} noSpacing withParser>
        {twitter.autoLink(item.full_text, {
          ...item.entities,
          ...item.extended_entities,
        })}
      </Content>
      <div className={styles.meta}>
        <div>
          <FontAwesomeProIcon
            className={styles.metaIcon}
            icon="heart"
            role="presentation"
            type="regular"
          />
          {item.favorite_count}
        </div>
        <div>{tweetDate}</div>
      </div>
    </Card>
  );
};

const CmsTwitter = ({ items }) => (
  <div className={styles.container}>
    {items.map(item => (
      <TwitterCard item={item} key={item?.id} />
    ))}
  </div>
);

export default CmsTwitter;
