import styles from './CmsFullHero.module.scss';
import {
  Button,
  Container,
  Content,
  FontAwesomeProIcon,
  Heading,
  Link,
  List,
  ListItem,
} from '@wienenergiegithub/ui-next';
import { trackInteraction } from '@wienenergiegithub/components/src/common/hooks/useTracking';
import React from 'react';
import classNames from 'classnames';
import { usePageData } from '@wienenergiegithub/components';

const CmsFullHero = ({
  heading,
  content,
  list,
  second_content: secondContent,
  image,
  href,
  interactionId,
  secondary_href: secondaryHref,
  secondary_interactionId: secondaryInteractionId,
  type,
}) => {
  const {
    layoutStructure: { navigationType },
  } = usePageData();

  const handleClick = id => () => {
    trackInteraction(id);
  };

  return (
    <div
      className={classNames(styles.container, {
        [styles.contentOnly]: !image,
      })}
    >
      <Container className={styles.content}>
        <div className={styles.innerContainer}>
          {heading && (
            <Heading
              as={type}
              className={styles.heading}
              size="h1"
              theme="contrast"
            >
              {heading}
            </Heading>
          )}

          {content && (
            <Content as="div" size="md" theme="contrast">
              {content.split('\n').map((i, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <p className={styles.subTitle} key={index}>
                  {i}
                </p>
              ))}
            </Content>
          )}
          {list?.length && (
            <List
              className={styles.list}
              icon={
                <FontAwesomeProIcon
                  icon="check"
                  role="presentation"
                  type="regular"
                />
              }
              type="checkmark"
            >
              {list.map((item, index) => (
                <ListItem
                  // eslint-disable-next-line react/no-array-index-key
                  key={`listItem-${index}`}
                  theme="contrast"
                >
                  {item}
                </ListItem>
              ))}
            </List>
          )}
          {secondContent && (
            <Content as="div" size="md">
              {secondContent.split('\n').map((i, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <p key={index}>{i}</p>
              ))}
            </Content>
          )}
          {href && (
            <Button
              as={Link}
              className={styles.button}
              isElevated
              onClick={handleClick(interactionId)}
              target={href.target}
              to={href.url}
            >
              {href.title}
            </Button>
          )}
          {secondaryHref && (
            <Button
              as={Link}
              className={styles.button}
              isElevated
              onClick={handleClick(secondaryInteractionId)}
              target={secondaryHref.target}
              theme="ghost"
              to={secondaryHref.url}
            >
              {secondaryHref.title}
            </Button>
          )}
        </div>
      </Container>
      {image && (
        <>
          <div
            className={classNames(styles.fadingBackground, {
              [styles.isReduced]: navigationType === 'reduced',
            })}
            role="presentation"
          />
          <img
            alt=""
            className={styles.image}
            sizes="(max-width: 960px) 733px, 100vw"
            src={image.src}
            srcSet={image.srcSet}
          />
        </>
      )}
    </div>
  );
};

CmsFullHero.size = 'full';

export default CmsFullHero;
