import styles from './Newsletter.module.scss';
import screenStateEnum from './utils/screenStateEnum';
import React, { useEffect, useState } from 'react';
import { CancelToken } from 'axios';
import {
  Button,
  Card,
  CardBody,
  Checkbox,
  Content,
  FontAwesomeProIcon,
  Heading,
  InputField,
  InputMessage,
  Link,
  Radio,
} from '@wienenergiegithub/ui-next';
import { useTranslation } from '@wienenergiegithub/modules-next/hooks';
import {
  emailRegex,
  useSessionStorage,
  useTracking,
} from '@wienenergiegithub/components';
import api from '@wienenergiegithub/components/src/common/api';
import {
  Form,
  FormCheckbox,
  FormGroupLabel,
  FormRadio,
  FormRadioGroup,
  FormSubmit,
  useFormStore,
} from '@ariakit/react';

const Newsletter = ({ listIds, title, subtitle, buttonLabel }) => {
  const [storedValues, storeValues] = useSessionStorage(`newsletter`);
  const [screenState, setScreenState] = useState(screenStateEnum.INITIAL);
  const track = useTracking();
  const { t } = useTranslation('newsletter');
  const [values, setValues] = useState({
    anrede: '',
    vorname: '',
    nachname: '',
    email: '',
    accepted: false,
  });
  const form = useFormStore({ values, setValues });

  form.useValidate(() => {
    if (!values.anrede) {
      form.setError('anrede', t('salutationError'));
    }

    if (!values.vorname) {
      form.setError('vorname', t('firstNameError'));
    }

    if (!values.nachname) {
      form.setError('nachname', t('lastNameError'));
    }

    if (!emailRegex.test(values.email) || !values.email) {
      form.setError('email', t('emailError'));
    }

    if (!values.accepted) {
      form.setError('accepted', t('acceptPrivacyError'));
    }
  });

  form.useSubmit(async () => {
    const { anrede, vorname, nachname, email } = values;

    try {
      storeValues(true);
      setScreenState(screenStateEnum.FINISHED);
      track({
        event: 'virtualPageView',
        title: '/newsletter/NL-Erfolg',
      });
      const source = CancelToken.source();
      setTimeout(() => {
        source.cancel();
      }, 10000);
      await api.post(
        '/newsletter/v1/subscribe',
        {
          anrede,
          listIds,
          vorname,
          nachname,
          email,
        },
        { cancelToken: source.token, timeout: 10000 },
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  });

  useEffect(() => {
    if (storedValues) {
      setScreenState(screenStateEnum.FINISHED);
    }
  }, []);

  return (
    <Card id="newsletter">
      <CardBody>
        {screenState === screenStateEnum.INITIAL && (
          <div className={styles.content}>
            <Heading align="center" as="h1" size="h4">
              {title}
            </Heading>
            <Content align="center" className={styles.subtitle} withParser>
              {subtitle}
            </Content>
            <Form className={styles.form} store={form}>
              <FormRadioGroup
                className={styles.radioGroup}
                name="anrede"
                store={form}
              >
                <FormGroupLabel
                  className={styles.formLabel}
                  name="anrede"
                  store={form}
                >
                  {t('salutation')}
                </FormGroupLabel>
                <div className={styles.radioGroupContainer}>
                  <FormRadio
                    label={t('mrs')}
                    name="anrede"
                    render={
                      <Radio
                        label={t('mrs')}
                        value="Frau"
                        variant="horizontal"
                      />
                    }
                    store={form}
                    value="Frau"
                  />
                  <FormRadio
                    label={t('mr')}
                    name="anrede"
                    render={
                      <Radio
                        label={t('mr')}
                        value="Herr"
                        variant="horizontal"
                      />
                    }
                    store={form}
                    value="Herr"
                  />
                  <FormRadio
                    label={t('non-binary')}
                    name="anrede"
                    render={
                      <Radio
                        label={t('non-binary')}
                        value="Divers"
                        variant="horizontal"
                      />
                    }
                    store={form}
                    value="Divers"
                  />
                </div>
              </FormRadioGroup>
              <InputMessage name="anrede" store={form} />
              <div className={styles.nameWrapper}>
                <InputField
                  label={t('firstName')}
                  name="vorname"
                  store={form}
                  variant="closed"
                />
                <InputField
                  label={t('lastName')}
                  name="nachname"
                  store={form}
                  variant="closed"
                />
              </div>
              <InputField
                className={styles.emailInput}
                label={t('email')}
                name="email"
                store={form}
                type="email"
                variant="closed"
              />
              <div />
              <FormCheckbox
                name="accepted"
                render={
                  <Checkbox
                    label={
                      <Content className={styles.legalText} noSpacing>
                        {t('privacyPreLink')}
                        <Link target="_blank" to="/datenschutz/">
                          {t('privacyLink')}
                        </Link>
                        {t('privacyPostLink')}
                      </Content>
                    }
                  />
                }
                store={form}
              />
              <InputMessage name="accepted" store={form} />
              <FormSubmit
                render={
                  <Button className={styles.submit} isElevated>
                    {buttonLabel}
                  </Button>
                }
                store={form}
                type="submit"
              />
            </Form>
          </div>
        )}
        {screenState === screenStateEnum.FINISHED && (
          <div className={styles.content}>
            <FontAwesomeProIcon
              className={styles.contentIcon}
              icon="envelope-open-text"
              role="presentation"
            />
            <Heading align="center" as="h3" size="h4">
              {t('successHeader')}
            </Heading>
            <Content align="center" noSpacing>
              {t('successDescription')}
            </Content>
          </div>
        )}
      </CardBody>
    </Card>
  );
};
export default Newsletter;
